export default [
  {
    key: 'increasement',
    sortable: false,
    sortField: '',
    label: '#',
    thClass: 'w-50px',
  },
  {
    key: 'userName',
    sortable: true,
    sortField: 'userName',
    label: 'field.userName',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'phoneNumber',
    label: 'field.phoneNumber',
  },
  {
    key: 'wallet',
    label: 'Balance',
  },
  {
    key: 'telegramId',
    label: 'Telegram ID',
  },
  {
    key: 'status',
    sortable: true,
    sortField: 'isEnable',
    label: 'field.status',
  },
  {
    key: 'actions',
    label: 'general.action',
  },
]
